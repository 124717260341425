import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Link, Routes, Navigate, useLocation } from 'react-router-dom';
import { BarChart, Activity as ActivityIcon, PieChart, Users as UsersIcon, Search, Settings, HelpCircle, LogOut } from 'lucide-react';

interface MenuItem {
  name: string;
  icon: React.ReactNode;
  path: string;
  isClickable?: boolean;
  isDisabled?: boolean;
  action?: () => void;
}

interface LookerDashboardProps {
  url: string;
}

const LookerDashboard: React.FC<LookerDashboardProps> = ({ url }) => {
  const [isReportLoading, setIsReportLoading] = useState(true);

  const handleReportLoad = () => {
    setIsReportLoading(false);
  };

  return (
    <div className="relative h-[calc(100vh+25px)]">
      {isReportLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-100">
          <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-gray-900"></div>
        </div>
      )}
      <iframe
        src={url}
        width="100%"
        height="100%"
        style={{ border: 'none' }}
        onLoad={handleReportLoad}
        title="Looker Dashboard"
      />
    </div>
  );
};

const Sidebar: React.FC = () => {
  const location = useLocation();

  const menuItems: MenuItem[] = [
    { name: 'My Dashboard', icon: <BarChart size={20} />, path: '/activity', isClickable: false },
    { name: 'Activity', icon: <ActivityIcon size={20} />, path: '/activity' },
    { name: 'Insights', icon: <PieChart size={20} />, path: '/insights' },
    { name: 'Users', icon: <UsersIcon size={20} />, path: '/users' },
    { name: 'Queries', icon: <Search size={20} />, path: '/queries', isDisabled: true },
    { name: 'Admin', icon: <Settings size={20} />, path: '/admin', isDisabled: true },
  ];

  const bottomMenuItems: MenuItem[] = [
    { name: 'Support', icon: <HelpCircle size={20} />, path: '', action: () => window.location.href = 'mailto:support@yellowdot.ai' },
    { name: 'Settings', icon: <Settings size={20} />, path: '/settings', isDisabled: true },
    { name: 'Log out', icon: <LogOut size={20} />, path: '/logout', isDisabled: true },
  ];

  const renderMenuItem = (item: MenuItem) => {
    if (item.isClickable === false) {
      return (
        <div className="flex items-center space-x-2 w-full p-2 mb-2">
          {item.icon}
          <span>{item.name}</span>
        </div>
      );
    }

    const isActive = location.pathname === item.path;
    const buttonClass = `flex items-center space-x-2 w-full p-2 mb-2 ${
      item.isDisabled
        ? 'text-[#A9B8CB] cursor-not-allowed'
        : isActive
        ? 'bg-gray-800 text-[#FAE131]'
        : 'hover:bg-gray-800'
    }`;

    const iconClass = isActive && !item.isDisabled ? 'text-[#FAE131]' : '';

    if (item.isDisabled) {
      return (
        <div className={buttonClass}>
          {item.icon}
          <span>{item.name}</span>
        </div>
      );
    }

    if (item.action) {
      return (
        <button onClick={item.action} className={buttonClass}>
          <span className={iconClass}>{item.icon}</span>
          <span>{item.name}</span>
        </button>
      );
    }

    return (
      <Link to={item.path} className="block">
        <button className={buttonClass}>
          <span className={iconClass}>{item.icon}</span>
          <span>{item.name}</span>
        </button>
      </Link>
    );
  };

  return (
    <div className="w-48 bg-gray-900 text-white h-screen p-4 flex flex-col">
      <div className="mb-8">
        <img src="/yd_logo_white.png" alt="Yellow Dot Logo" className="p-4 max-w-full h-auto" />
      </div>
      <div className="flex-grow">
        {menuItems.map((item, index) => (
          <React.Fragment key={item.name}>
            {renderMenuItem(item)}
            {index === 0 && <div className="border-t border-white my-2"></div>}
            {index === 4 && <div className="border-t border-white my-2"></div>}
          </React.Fragment>
        ))}
      </div>
      <div>
        {bottomMenuItems.map((item) => renderMenuItem(item))}
      </div>
    </div>
  );
};

const ActivityPage: React.FC = () => (
  <LookerDashboard url="https://lookerstudio.google.com/embed/reporting/c7a06f25-3fdd-477b-b03e-342651fe4ffa/page/0s1BE" />
);
const Insights: React.FC = () => (
  <LookerDashboard url="https://lookerstudio.google.com/embed/reporting/ec63d098-1453-4b0a-9167-126d70ef5a6e/page/0s1BE" />
);
const UsersPage: React.FC = () => (
  <LookerDashboard url="https://lookerstudio.google.com/embed/reporting/486d88ae-2ba3-458e-b5fa-947dd8201f3d/page/0s1BE" />
);
const Queries: React.FC = () => <LookerDashboard url="YOUR_QUERIES_DASHBOARD_URL_HERE" />;
const Admin: React.FC = () => <LookerDashboard url="YOUR_ADMIN_DASHBOARD_URL_HERE" />;
const Support: React.FC = () => <LookerDashboard url="YOUR_SUPPORT_DASHBOARD_URL_HERE" />;
const SettingsPage: React.FC = () => <LookerDashboard url="YOUR_SETTINGS_DASHBOARD_URL_HERE" />;
const Logout: React.FC = () => <div>Logout functionality would be implemented here</div>;

const Dashboard: React.FC = () => {
  return (
    <Router>
      <div className="flex">
        <Sidebar />
        <div className="flex-grow">
          <Routes>
            <Route path="/" element={<Navigate to="/activity" replace />} />
            <Route path="/activity" element={<ActivityPage />} />
            <Route path="/insights" element={<Insights />} />
            <Route path="/users" element={<UsersPage />} />
            <Route path="/queries" element={<Queries />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/support" element={<Support />} />
            <Route path="/settings" element={<SettingsPage />} />
            <Route path="/logout" element={<Logout />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default Dashboard;